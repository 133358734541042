<template>
  <v-form ref="form">
    <v-container>
      <v-row align="center" justify="center">
        <v-col align="center" justify="center">
          <v-container style="width: 320px;">
            <v-row>
              <v-col
                class="pa-0 px-1"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="passwordRules"
                  :type="showPassword ? 'text' : 'password'"
                  :label="$t('updatePassword.passwordLabel')"
                  @keydown.enter="updatePassword()"
                  @click:append="showPassword = !showPassword"
                >
                  <v-icon slot="prepend" color="primary lighten-1"
                    >mdi-lock</v-icon
                  >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="pa-0 px-1"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  v-model="confirmPassword"
                  prepend-icon="mdi-empty"
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="confirmPasswordRules"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  :label="$t('updatePassword.confirmPasswordLabel')"
                  @keydown.enter="updatePassword()"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-1" cols="12">
                <v-btn
                  depressed
                  tile
                  v-html="$t('updatePassword.submitButton')"
                  class="primary white--text full-width"
                  @click="updatePassword()"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";
import { api } from "@/helpers/ApiCalls";

export default {
  name: "PasswordReset",

  data() {
    return {
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
      accessToken: this.stripAccessToken(window.location.href),
      passwordRules: [
        (v) =>
          // eslint-disable-next-line no-useless-escape
          /^(?!.*(.)\1\1.*)(?=^.{8,}$)((?=.*\d)|(?=.*[£!@#\$%\^&\*]))((?=.*[A-Z])|(?=.*[a-z])).*$/.test(
            v
          ) || this.$t("updatePassword.passwordAICRules"),
      ],
      confirmPasswordRules: [
        (v) =>
          (!!v && v.length >= 8) || this.$t("updatePassword.passwordRules"),
        (v) =>
          (!!v && v === this.password) ||
          this.$t("updatePassword.passwordMatch"),
      ],
    };
  },

  computed: {
    ...mapGetters(["axiosSource"]),
  },

  methods: {
    resetForm() {
      this.$refs.form.reset();
    },
    stripAccessToken(url) {
      if (url.indexOf("?code") > 0) {
        return url.substr(url.indexOf("?code") + 6, url.length);
      }
      return null;
    },
    updatePassword() {
      if (this.$refs.form.validate()) {
        const form = {
          accessToken: this.accessToken,
          password: this.password,
        };

        this.$store.dispatch("passwordResetLoading", true);

        this.$store.dispatch("updatePassword", form).then(
          (response) => {
            this.$store.dispatch("passwordResetLoading", false);
            this.resetForm();

            const notification = {
              show: true,
              result: true,
              message: response.message,
              delay: 8000,
            };

            this.$store.dispatch("showSnackbar", notification);

            this.$store.dispatch("showLoginDialog", true);
            router.push({ name: "Home" }).catch(() => {});
          },
          (error) => {
            this.loading = false;

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);

            if (api.authenticationError(error.code)) {
              setTimeout(function () {
                router.push({ name: "Home" }).catch(() => {});
              }, api.authErrorRedirectTimeout);
            }
          }
        );
      }
    },
  },
};
</script>
